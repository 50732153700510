<template lang="pug">
  b-row
    b-col.mb-4.mb-xl-0(
      md='12'
      lg='12'
      xl='5')
      b-card.manager-accounts-account-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body.d-flex.justify-content-start.align-items-center
          template(v-if='panelShow')
            .panel-picture.mr-4
              b-avatar.wc-bubble.wc-bubble-md.wc-bubble-br.mr-3.shadow(
                :src='panel.data.attributes.portrait_path'
                variant='white'
                alt='')
                template(v-if='!panel.data.attributes.portrait_path' #default)
                  .wc-bubble-content.wc-bubble-linked.bg-secondary
                    .i-wrapper
                      i.fal.fa-fw.fa-2x.fa-user.text-white
            .panel-info
              .panel-info-email.text-break
                | {{ panel.data.attributes.email }}
              .panel-info-id.small.text-muted
                | {{ panel.data.id }}
              .panel-ip.my-3
                .panel-info-current-ip
                  span.font-weight-bold
                    | {{ $t('activerecord.attributes.account.current_sign_in_ip') }}
                  |  {{ panel.data.attributes.current_sign_in_ip }}
                .panel-info-last-ip
                  span.font-weight-bold
                    | {{ $t('activerecord.attributes.account.last_sign_in_ip') }}
                  |  {{ panel.data.attributes.last_sign_in_ip }}
              .panel-info-sign-in
                span.fa-stack.mr-2.text-secondarylight
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-sign-in
                span
                  | {{ $tc('account.sign_in_count', panel.data.attributes.sign_in_count, { count: panel.data.attributes.sign_in_count } ) }}
          template(v-else)
            wc-loaders-heading-avatar
    b-col.mb-4.mb-xl-0(
      md='12'
      lg='12'
      xl='4')
      b-card.manager-accounts-account-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body
          template(v-if='panelShow')
            b-list-group.justify-content-start.p-0.h-100(flush)
              b-list-group-item.border-0
                span.fa-stack.mr-2(:class="[panel.data.attributes.active ? 'text-success' : 'text-warning' ]")
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x(:class="[panel.data.attributes.active ? 'fa-eye' : 'fa-eye-slash' ]")
                span
                  | {{ panel.data.attributes.active ? $t('shared.is_active') : $t('shared.is_inactive') }}
              b-list-group-item.border-0
                span.fa-stack.mr-2(:class="[panel.data.attributes.confirmed ? 'text-success' : 'text-warning' ]")
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x(:class="[panel.data.attributes.confirmed ? 'fa-check' : 'fa-times' ]")
                span
                  | {{ panel.data.attributes.confirmed ? $t('account.is_confirmed') : $t('account.is_unconfirmed') }}
              b-list-group-item.border-0
                span.fa-stack.mr-2(:class="[panel.data.attributes.locked ? 'text-warning' : 'text-success' ]")
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x(:class="[panel.data.attributes.locked ? 'fa-lock-alt' : 'fa-lock-open-alt' ]")
                span
                  | {{ panel.data.attributes.locked ? $t('account.is_locked') : $t('account.is_unlocked') }}
              b-list-group-item.border-0
                span.fa-stack.mr-2(:class="[panel.data.attributes.acceptance_at ? 'text-success' : 'text-danger' ]")
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-shield-alt
                span
                  | {{ panel.data.attributes.acceptance_at ? $t('account.is_accepted') : $t('account.is_unaccepted') }}
          template(v-else)
            wc-loaders-list(:lines='5')
    b-col(
      md='12'
      lg='12'
      xl='3')
      b-card.manager-accounts-account-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body
          template(v-if='panelShow')
            b-list-group.justify-content-start.p-0.h-100(flush)
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.account.current_sign_in_at')")
                span.fa-stack.mr-2.text-secondarylight
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-calendar-check
                span
                  | {{ panel.data.attributes.current_sign_in_at || $t('account.never_logged_in') }}
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.account.last_sign_in_at')")
                span.fa-stack.mr-2.text-secondarylight
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-calendar-day
                span
                  | {{ panel.data.attributes.last_sign_in_at || $t('account.never_logged_in') }}
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.shared.updated_at')")
                span.fa-stack.mr-2.text-secondarylight
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-calendar-edit
                span
                  | {{ panel.data.attributes.updated_at }}
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.shared.created_at')")
                span.fa-stack.mr-2.text-secondarylight
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-calendar-plus
                span
                  | {{ panel.data.attributes.created_at }}
          template(v-else)
            wc-loaders-list(:lines='5')
</template>

<script>
import { Panel } from '@common/panel'
import WcLoadersHeadingAvatar from '@components/shared/loaders/WcLoadersHeadingAvatar'
import WcLoadersList from '@components/shared/loaders/WcLoadersList'

export default {
  name: 'manager-accounts-account-panel',
  mixins: [Panel],
  components: {
    WcLoadersHeadingAvatar,
    WcLoadersList,
  },
  data() {
    return {
      cacheType: 'manager/accounts/getPanel',
      cachePayload: { id: this.$route.params.account_id },
    }
  },
}
</script>
