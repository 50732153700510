<template lang="pug">
  content-placeholders.wc-loaders-list.flex-fill(
    :rounded='true')
    .list(v-for='line in lines')
      content-placeholders-text.vue-content-placeholders-list__item(:class='lineClass' :lines='1')
      content-placeholders-text.vue-content-placeholders-list__line(:class='lineClass' :lines='1')
</template>

<script>
import ContentPlaceholders from 'vue-content-placeholders/src/components/ContentPlaceholders'
import ContentPlaceholdersText from 'vue-content-placeholders/src/components/ContentPlaceholdersText'

export default {
  name: 'wc-loaders-list',
  components: {
    ContentPlaceholders,
    ContentPlaceholdersText,
  },
  props: {
    lines: {
      type: Number,
      default: 3,
    },
    lineClass: String,
  },
}
</script>

<style lang="scss">
@import '@assets/css/plugins/bootstrap/config';

.vue-content-placeholders.wc-loaders-list {
  .list {
    display: flex;
    flex: 1;
    flex-direction: row;
    .vue-content-placeholders-list__item,
    .vue-content-placeholders-list__line {
      margin-top: 0;
    }
    .vue-content-placeholders-text__line {
      background: $vue-content-placeholders-secondary-color;
    }
    .vue-content-placeholders-list__item {
      width: $vue-content-placeholders-line-height;
      margin-right: 1rem;
      .vue-content-placeholders-text__line {
        width: $vue-content-placeholders-line-height;
      }
    }
    .vue-content-placeholders-list__line {
      width: 100%;
      > div {
        width: 100%;
      }
    }
    &:nth-child(4n + 1) .vue-content-placeholders-list__line > div {
      width: 80%;
    }
    &:nth-child(4n + 2) .vue-content-placeholders-list__line > div {
      width: 100%;
    }
    &:nth-child(4n + 3) .vue-content-placeholders-list__line > div {
      width: 70%;
    }
    &:nth-child(4n + 4) .vue-content-placeholders-list__line > div {
      width: 85%;
    }
    &:last-child .vue-content-placeholders-text__line {
      margin-bottom: 0;
    }
  }
}
</style>
