<template lang="pug">
  content-placeholders.wc-loaders-heading-avatar.flex-fill(
    :class='styleClass'
    :rounded='true')
    content-placeholders-heading(:img='true')
</template>

<script>
import ContentPlaceholders from 'vue-content-placeholders/src/components/ContentPlaceholders'
import ContentPlaceholdersHeading from 'vue-content-placeholders/src/components/ContentPlaceholdersHeading'

export default {
  name: 'wc-loaders-heading-avatar',
  components: {
    ContentPlaceholders,
    ContentPlaceholdersHeading,
  },
  props: {
    styleClass: String,
  },
}
</script>

<style lang="scss">
@import '@assets/css/plugins/bootstrap/config';

.vue-content-placeholders.wc-loaders-heading-avatar {
  .vue-content-placeholders-heading__title {
    background: $vue-content-placeholders-primary-color;
  }
  .vue-content-placeholders-heading__img,
  .vue-content-placeholders-heading__subtitle {
    background: $vue-content-placeholders-secondary-color;
  }
  .vue-content-placeholders-heading__img {
    width: $wc-bubble-md-size;
    height: $wc-bubble-md-size;
    border-radius: 50%;
  }
  &.avatar-xxs .vue-content-placeholders-heading__img {
    width: $wc-bubble-xxs-size;
    height: $wc-bubble-xxs-size;
  }
  &.avatar-xs .vue-content-placeholders-heading__img {
    width: $wc-bubble-xs-size;
    height: $wc-bubble-xs-size;
  }
  &.avatar-sm .vue-content-placeholders-heading__img {
    width: $wc-bubble-sm-size;
    height: $wc-bubble-sm-size;
  }
  &.avatar-lg .vue-content-placeholders-heading__img {
    width: $wc-bubble-lg-size;
    height: $wc-bubble-lg-size;
  }
}
</style>
